import toggleElement from './toggle-element';

function categories(prop) {
	if (document.querySelectorAll('.js-toggle').length) {
		const domCategoriesToggler = document.querySelector('.js-categories-toggler');
		const domCategories = document.querySelector('.js-categories');
		const domCategoryToggleButtons = domCategories.querySelectorAll('.js-toggle');

		domCategoryToggleButtons.forEach((button) => {
			toggleElement(button);
		});

		toggleElement(domCategoriesToggler);
	}
}

export default categories;
