function toggleElement(element, toggledClass = 'is-toggled') {
  if (!element) {
    return;
  }

  element.addEventListener('click', function() {
    element.classList.toggle(toggledClass);
  })
}

export default toggleElement;